import React, {Component} from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl';


import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import styled from "@emotion/styled";
import {createPaymentIntent} from "./functions";
import {navigate} from "@reach/router";
import {fonts} from "../../styles/variables";
import {postReservation} from "../cloudbeds/functions";
//import {getFullName} from "../auth/authFunctions";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

const FlexDiv = styled.div`
display:block;
width:100%;
margin-bottom:7px;
`




const PayButton = styled.button`
cursor:pointer;
width:98%;
margin-bottom:10px;
margin-top:20px;
height:42px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem !important;
  color: #fff;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}


`
const InputDiv = styled.div`

`
const Label = styled.label`
text-align:left !important;
input{
width:100% !important;

font-size:16px !important;
 }
padding-left:6px;
font-weight:bold;
`

const DDiv = styled.div`
width:100%;
padding-left:12px;
color:rgb(68,68,68);
`



const StyledSpinner = styled.div`
width:100%;
height:100%;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;

`


class _CardForm extends Component<any,any> {
  state = {
    ready:false,
    errorMessage: '',
    amount:this.props.amount,
    showSpinner:true
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleReady = () => {
    //console.log('[ready]');
    this.setState({
        ready: true,
        showSpinner:false

    });
    this.setState({
        ready: true,
        showSpinner:false

    });

  };

   handleSubmit = (evt) => {
    evt.preventDefault();
     if (this.props.stripe) {
       this.setState({showSpinner: true});

       let stripe_local=this.props.stripe
       let cus_id = ''
       if(cus_id===undefined||cus_id===''||cus_id==='undefined'||cus_id===null){
         cus_id=''
       }
       let amount = this.props.amount
       //console.log(evt)
       let paymentIntent = createPaymentIntent(amount, 'eur',cus_id, false, 'manual');
       paymentIntent.then(function(pi) {
         if(pi.status==='requires_payment_method') {
           let descr = '' // dummy param

           navigate('/voucher-success')

         }
         }.bind(this));








    } else {
       this.setState({showSpinner: false});

       //console.log("Stripe.js hasn't loaded yet or card details incomplete");
    }
  };

   componentDidMount(){


       this.setState({showSpinner: false});


   }

  render() {
    return (
      <div className="CardDemo">
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <Loader
            type="Triangle"
            color="black"
            height={66}
            width={66}
           // timeout={30000} //3 secs

          />
        </StyledSpinner >

        <form style={{display:this.state.showSpinner ? 'none':'block'}} onSubmit={this.handleSubmit.bind(this)}>

            <div style={{textAlign:'left', paddingLeft:'2%'}}>
            <FormattedMessage id={'bank_transfer_1'}/> <b>{this.props.amount.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2
            })} </b> € <FormattedMessage id={'bank_transfer_2'}/>
            </div>
<br/>
          <PayButton disabled={!this.props.stripe}> <FormattedMessage id={'btn_book'} /></PayButton>

          <br/>
          <br/>
            <FlexDiv style={{textAlign:'left', paddingLeft:'2%'}}>
              <Label>
            <FormattedMessage id={'bank_transfer_3'}/>
              </Label>
              <DDiv>2 in Seven GmbH</DDiv>
            </FlexDiv>


            <FlexDiv style={{textAlign:'left', paddingLeft:'2%'}}>
              <Label>
            <FormattedMessage id={'bank_transfer_4'}/>
              </Label>
              <DDiv>DE51 1101 0100 2217 2142 75</DDiv>
            </FlexDiv>

          <FlexDiv style={{textAlign:'left', paddingLeft:'2%'}}>
            <Label>

            <FormattedMessage id={'bank_transfer_5'}/>
            </Label>
            <DDiv>SOBKDEBBXXX</DDiv>


          </FlexDiv>

            <FlexDiv style={{textAlign:'left', paddingLeft:'2%'}}>
              <Label>
              <FormattedMessage id={'bank_transfer_7'}/>
              </Label>
              <DDiv>Solaris Bank AG</DDiv>

            </FlexDiv>



          <FlexDiv style={{textAlign:'left', paddingLeft:'2%'}}>
            <Label>
              <FormattedMessage id={'bank_transfer_6'}/>
            </Label>
              <DDiv> <FormattedMessage id={'bank_transfer_6_1'}/></DDiv>
            </FlexDiv>

<br/>
<br/>

          <div style={{textAlign:'left', paddingLeft:'2%'}}>
            <FormattedMessage id={'bank_transfer_8'}/>
          </div>

<br/>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
        </form>
        </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class BankTransferPay extends Component<any,any> {
  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
      countdown: 3,
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // Remove our existing Stripe script to keep the DOM clean.
   // document.getElementById('stripe-script').remove();
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.id = 'stripe-script';
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const countdown = setInterval(() => {
        this.setState({countdown: this.state.countdown - 1});
      }, 1000);
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        clearInterval(countdown);
        this.setState({
          stripe: window.Stripe('pk_live_FrncSr45TM7v7k9MzczmpLlI00iT1gKayE'),
        });
      }, 3000);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardForm
            postBooking={this.props.postBooking}
            amount={this.props.amount}
            countdown={this.state.countdown}
            handleResult={this.props.handleResult}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

import React from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import styled from "@emotion/styled";
import {fonts} from "../../styles/variables";
import {createPaymentIntent} from "./functions";
import {navigate} from "@reach/router";




const Label = styled.label`

padding-left:6px;
font-weight:bold;
`
const  style={
  size: 'small',
  color: 'gold',
  shape: 'rect'
}

 const DivDesktop = styled.div`
  display:block;

.paypal-button-text {
   font-family:${fonts.button} !important;
   }

.paypal-button .paypal-button-shape-rect {
 font-family:${fonts.button} !important;
letter-spacing:2px !important;
text-transform:uppercase !important;
  border-radius: 10rem !important;

  }

  .paypal-button paypal-button-number-0 paypal-button-layout-horizontal paypal-button-shape-rect paypal-button-branding-branded paypal-button-number-single paypal-button-env-sandbox paypal-should-focus paypal-button-label-checkout paypal-button-color-gold paypal-button-logo-color-blue{
    border-radius: 10rem;
background-clip:none;
  }
 @media screen and (max-width:760px){
 display:none;
 }
 `

const DivMobile = styled.div`
  display:none;
   font-family:${fonts.button} !important;
.paypal-button-text {
   font-family:${fonts.button} !important;
   }

.paypal-button .paypal-button-shape-rect{
  font-family:${fonts.button} !important;
letter-spacing:2px !important;
text-transform:uppercase !important;
  border-radius: 10rem !important;

  }

 @media screen and (max-width:760px){
 display:block;
 }
 `

export default class PaypalPay extends React.Component<any,any> {
  constructor(props){
    super(props)
  }
  render() {
    const onSuccess = (payment) => {
      // Congratulation, it came here means everything's fine!
      //console.log("The payment was succeeded!", payment);
     // const postBooking = this.props.postBooking
      let cus_id = '';
      if(cus_id===undefined||cus_id===''||cus_id==='undefined'||cus_id===null){
        cus_id=''
      }
      let paymentIntent =  createPaymentIntent(this.props.amount, 'eur',cus_id, false,'manual');
      paymentIntent.then(function(pi) {

        navigate('/voucher-success')

      let descr = 'PayPal On Website'+pi.id

      // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }.bind(this))


    }

    const onCancel = (data) => {
      // User pressed "cancel" or close Paypal's popup!
      //console.log('The payment was cancelled!', data);
      //todo: cancellation notification

      // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
      // The main Paypal's script cannot be loaded or somethings block the loading of that script!
      //console.log("Error!", err);
      // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
      //todo: loading error notification

      // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    let env = 'production'; // you can set here to 'production' for production
    let currency = 'EUR'; // or you can set this value from your props or state
    let total = this.props.amount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
      sandbox:    'YOUR-SANDBOX-APP-ID',
      production: 'AaCNGYcatFL1_2-stYqfl_KmgZl1gA2FLZI-bWFbBgnx1funpvfTsapUAxEvZ85DS7Fv1I_2Znb_VReL',
    }
    // In order to get production's app-ID, you will have to send your app to Paypal for approval first
    // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
    //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
    // For production app-ID:
    //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

    // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
    return (
      <div>
<DivMobile>
  <PaypalExpressBtn  style={{
    size: 'medium',
    color: 'gold',
    shape: 'pill',
    borderRadius:'10rem !important'
  }} env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />


</DivMobile>

<DivDesktop>
        <PaypalExpressBtn  style={{
          size: 'large',
          color: 'gold',
          shape: 'pill',
          borderRadius:'10rem !important',
          fontFamily:`${fonts.button}`
        }} env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
</DivDesktop>
      </div>
        );
  }
}

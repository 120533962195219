import * as React from 'react'
import styled from '@emotion/styled'
import FloatingLabelInput from 'react-floating-label-input';
import { ElementConfig } from 'react'
import { FaCcPaypal, FaCreditCard } from 'react-icons/fa'
import { AiFillBank } from 'react-icons/ai'
import Select, {components} from 'react-select'
import { fonts } from '../../styles/variables'
import { FormattedMessage } from 'react-intl';
import PaypalExpress from "../stripe/PaypalProvider";
import { AsyncDemo } from '../stripe/StripeProvider'
import { CreditCardPay } from '../stripe/CreditCardVoucher'
import PaypalPay from '../stripe/PaypalVoucher'
import { BankTransferPay } from '../stripe/BankTransferVoucher'

const v7 = require('../../../assets/images/save-v7.jpg');

const arrowDown = require('../../../assets/images/down-arrow.png')

let paymentMethods = [
  {value: 1, label: 'Credit Card', icon:<FaCreditCard style={{fontSize:'25px'}}/> },
  {value: 2, label: 'PayPal',icon:<FaCcPaypal style={{fontSize:'25px'}}/>},
  {value: 5, label: 'Bank Transfer',icon:<AiFillBank/>},



]

const ArrowImg = styled.img`
fill:black !important;
width:16px;
height:16px;
margin: 0 11px 0 11px;

`

const customSingleValue = ({ data }) => (
  <div className="input-select" >
    <div className="input-select__single-value" style={{display:'flex'}}>
      { data.icon && <span style={{display:'flex', alignItems:'center', marginRight:'7px'}} className="input-select__icon">{ data.icon }</span> }
      <span>{ data.label }</span>
    </div>
  </div>
);


const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowImg src={arrowDown}/>
    </components.DropdownIndicator>
  );
};


const customGuestStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: 'flex',

      fontSize: '19px',
      borderTop: '1px solid lightgrey',
      borderLeft: '1px solid lightgrey',
      borderRight: '1px solid lightgrey',
      borderBottom: isFocused ? '2px solid #54aaf1' :'1px solid lightgrey',
      boxShadow: isFocused ? null : null,

      "&:hover": {
        // Overwrittes the different states of border
        borderColor: isFocused ? "none" : "rgba(68,68,68,.5)",


      }
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      background: isSelected ? 'rgb(24,140,236)' : 'white',
      color: isSelected ? 'white' : 'rgb(68,68,68)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      "&:hover": {
        // Overwrittes the different states of border
        background: isSelected ? "rgba(24,140,236,1)" : "rgba(24,140,236,.2)",


      }

    };
  },



  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


const Div = styled.div`
width:30%;
margin-left:auto;
margin-right:auto;
padding-top:1rem;

 @media screen and (max-width: 1295px) {
display:block;
width:80%;


   }
`

const Product = styled.div`

`

const ProductImg = styled.img`
width:100%;
margin-bottom:1rem;
`

const ProductTitle = styled.h1`
`

const ProductDescr = styled.div`
`
const Hint = styled.div`
font-size:0.8rem;
`

const ProductCount = styled.div`
margin-top:1rem;
`

const Total= styled.div`

`

const Amount = styled.div`
`

const TotalAmount = styled.div`
display:flex;
justify-content:space-between;
width:50%;
margin-bottom:2rem;
margin-left: 50px;
`

const StyledInput = styled(FloatingLabelInput)`
width: 100%;
margin-bottom:2rem;
 @media screen and (max-width: 400px) {
   width:320px;

  }
margin-top:10px;
transition: all .2s ease;
color:rgb(68,68,68,1);
label{
left:7px;
}
input{
width:100%;
padding-left:7px;
padding-top:4px;
padding-bottom:2px;
 background:var(--bg);
color:var(--input);
}
:focus{
border-bottom:1px solid rgba(68,68,68,.2);
background: rgba(233, 231, 175,.2);
}
`


const Contact = styled.div`
`

const Location = styled.div`
display:flex;
justify-content:space-between;
`

const Payment = styled.div`
margin-top:2rem;
`

const StyledSelect = styled.div`
  width:100%;
  position:relative;
  z-index:99999999;
  margin-bottom:1.5rem;
   font-family: "Theano Didot",Regular;
  font-size:19px;
  text-align:center;

    @media screen and (max-width:1295px){
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }



  `


const StyledDiv = styled.div`


`

const Span = styled.span`
padding-left:6px;
 font-family:${fonts.engraversBold} !important;
 font-weight:bold;
 text-transform:uppercase;

`


class BuyVoucher extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
paymentMethods:paymentMethods,
      selectedPaymentMethod:paymentMethods[0]
  }

  this.handleCreditCard=this.handleCreditCard.bind(this);
    this.handlePaypal=this.handlePaypal.bind(this);
    this.handleSepa = this.handleSepa.bind(this)
  }



  componentDidMount() {

  }

  handlePaypal(){
    this.setState({
      selectedPaymentMethod:paymentMethods[1],
      paypal: true,
      paySepa: false,
      payKlarna: false,
      payGiropay: false,
      payCreditCard: false,
      payMethodText:'PayPal'


    })

  }

  handleSepa(){
    this.setState({
      selectedPaymentMethod:paymentMethods[2],

      paypal: false,
      paySepa: true,
      payGiropay: false,
      payCreditCard: false,
      payKlarna: false,
      payMethodText:'SEPA Direct Debit'



    })

  }

  handleCreditCard(){
    this.setState({
      selectedPaymentMethod:paymentMethods[0],
      paypal: false,
      paySepa: false,
      payKlarna: false,
      payGiropay: false,
      payCreditCard: true,
      payMethodText:'Credit Card'



    })

  }

  handleKlarna(){
    this.setState({
      paypal: false,
      paySepa: false,
      payKlarna: true,
      payGiropay: false,
      payCreditCard: false,
      payMethodText:'Klarna'



    })

  }

  handleGiropay(){
    this.setState({
      paypal: false,
      paySepa: false,
      payKlarna: false,
      payGiropay: true,
      payCreditCard: false,
      payMethodText:'Giropay'



    })

  }

  handlePaymentMethodSelection = (selectedPaymentMethod) => {
    this.setState({selectedPaymentMethod: selectedPaymentMethod.value});
    //console.log(selectedPaymentMethod.value)
    if(selectedPaymentMethod.value===1){
      this.handleCreditCard()
    }
    if(selectedPaymentMethod.value===2){
      this.handlePaypal()   }

    if(selectedPaymentMethod.value===3){
      this.handleKlarna()
    }
    if(selectedPaymentMethod.value===4){
      this.handleGiropay()
    }
    if(selectedPaymentMethod.value===5){
      this.handleSepa()

    }
    if(selectedPaymentMethod.value===6){
    }

    if(selectedPaymentMethod.value===7){
    }

    // this.calculateFinalPrice(this.state.payTotal, selectedGuests.value, this.state.firstSelection, this.state.secondSelection, this.state.thirdSelection, this.state.fourthSelection)


  }

  render() {
    return(


        <Div id={'component'}>
          <Product>
            <ProductImg src={v7}/>
            <ul>
              <li>5 Übernachtungen</li>
              <li>Frühstück</li>
              <li>2h Home Office</li>
            </ul>

            <ProductTitle></ProductTitle>
            <ProductDescr></ProductDescr>
            <TotalAmount>
              <Total>Total:</Total> <Amount>500,00€</Amount>
            </TotalAmount>

          </Product>

          <Contact>

            <StyledInput label={'Firma'}  name="company" type="text"  />
            <StyledInput label={'Name'}  name="name" type="text" required />

          </Contact>

          <Payment>
            <Span><FormattedMessage id="payment_text_4" /></Span>
            <StyledSelect>
              <Select styles={customGuestStyles} components={{ DropdownIndicator,SingleValue: customSingleValue  }} onChange={this.handlePaymentMethodSelection}
                      value={this.state.selectedPaymentMethod}
                      clearable={false} options={this.state.paymentMethods} />
            </StyledSelect>
            {
              this.state.selectedPaymentMethod.value===1 &&
              <CreditCardPay amount={500} fullAmount={500}/>

            }       {
              this.state.selectedPaymentMethod.value===2 &&
              <PaypalPay amount={500} fullAmount={500}/>

          }       {
              this.state.selectedPaymentMethod.value===5 &&
              <BankTransferPay  amount={500} fullAmount={500}
              />
            }


          </Payment>
<br/>
<br/>
          <Hint>Unser Voucher ist bis zum 24.05.2020 erhältlich.</Hint>

    </Div>



    )
  }
}


export default BuyVoucher


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>

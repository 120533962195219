import * as React from 'react'
import styled from '@emotion/styled'

import IndexLayout from '../layouts'
import BikesDiv from "../components/specials/BikesDiv";
import LaundryDiv from "../components/specials/LaundryDiv";
import ServiceDiv from "../components/specials/ServiceDiv";
import HeaderDesktop from "../components/HeaderDesktop";
import Helmet from "react-helmet";
import BuyVoucher from '../components/voucher/BuyVoucher'

const Div = styled.div`

`

const SaveVilhelm7 = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Helmet

      title={'Unterstützen Sie uns! | #saveVILHELM7'}
      meta={[
        { name: 'description', content: 'Sichern Sie sich unseren Vilhelm7 500 EUR Voucher inkl. 5 ÜN, Frühstück und 2h Home Office.' },
        { name: 'keywords', content: 'Vilhelm7, V7, Apartments, voucher, save, vilhelm7, #savevilhelm7, Übernachtung'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop></HeaderDesktop>
    <Div>
     <BuyVoucher/>
    </Div>
  </IndexLayout>
)

export default SaveVilhelm7

import React, {Component} from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { FormattedMessage } from 'react-intl';


import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import styled from "@emotion/styled";
import {createPaymentIntent, updatePaymentIntent} from "./functions";
import {navigate} from "@reach/router";
import {fonts} from "../../styles/variables";
import {getAccessToken, getRatePlans} from "../cloudbeds/functions";
//import {getFullName} from "../auth/authFunctions";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};



const PayButton = styled.button`
cursor:pointer;
width:98%;
margin-bottom:10px;
margin-top:20px;
height:42px;
 display: flex;
 align-items:center;
 justify-content:center;
  border-radius: 10rem !important;
  color: #fff;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight:bold;
  font-size:19px;
margin-left:auto;
margin-right:auto;
font-family:${fonts.button};
letter-spacing:2px;
text-transform:uppercase;


:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #188cec;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

 :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54aaf1;
  border-radius: 10rem;
  z-index: -2;
}

:hover {
  color: #fff;
}

:hover:before {
  width: 100%;
}


   @media screen and (max-width: 1295px) {
z-index:999999;
}


`
const InputDiv = styled.div`

`
const Label = styled.label`
text-align:left !important;
padding-left:6px;
 font-family:${fonts.engraversBold} !important;
 font-weight:bold;
 text-transform:uppercase;
input{
width:100% !important;

font-size:16px !important;
 }
padding-left:6px;
font-weight:bold;
`

const StyledSpinner = styled.div`
width:100%;
height:100%;
transform: rotate(180deg);
display:flex;
justify-content:center;
align-items:center;

`

const Wrapper = styled.div`
.StripeElement{
padding-right:0px !important;
padding-left:0px !important;

}

`

const PaymentDiv = styled.div`
margin-bottom:0.7rem;
`


class _CardForm extends Component {
  state = {
    ready:false,
    errorMessage: '',
    amount:this.props.amount,
    showSpinner:false
  };



  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleReady = () => {
    //console.log('[ready]');
    this.setState({
        ready: true,
        showSpinner:false

    });

  };

   handleSubmit = (evt) => {
    evt.preventDefault();
     if (this.props.stripe) {
       this.setState({showSpinner: true});

       let stripe_local=this.props.stripe
       //let postBooking = this.props.postBooking
       //const str_cus_id = JSON.parse(window.localStorage.profile["https://dev-uv101:eu:auth0:com/user_metadata"]).stripe_customer_id;

       let amount = this.props.amount
       let cus_id = ''
       if(cus_id===undefined||cus_id===''||cus_id==='undefined'||cus_id===null){
         cus_id=''
       }
       //console.log(evt)
    let client_secret = createPaymentIntent(amount, 'eur',cus_id, false, 'automatic');
    client_secret.then(function(cs) {
      stripe_local.createPaymentMethod('card', {billing_details: {name: 'Siehe Customer'}}).then(function(result) {
        //console.log(result.paymentMethod) // Handle result.error or result.token
        stripe_local.confirmCardPayment(cs.client_secret, {
          payment_method: result.paymentMethod.id,
        }).then(function(res) {
          //console.log(res)
          if(res.paymentIntent.status==='succeeded') {
            navigate('/voucher-success')









          }
          else  {
            //todo: handle error -> show error notification
          }


        }.bind(this));
      }.bind(this));
    }.bind(this));




    } else {
       this.setState({showSpinner: false});

       //console.log("Stripe.js hasn't loaded yet or card details incomplete");
    }
  };

   componentDidMount(){

     if(!this.state.ready){
       this.setState({showSpinner: true});

     }
   }

  render() {
    return (
      <div className="CardDemo">
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <Loader
            type="Triangle"
            color="black"
            height={66}
            width={66}
           // timeout={30000} //3 secs

          />
        </StyledSpinner >

        <form style={{display:this.state.showSpinner ? 'none':'block'}} onSubmit={this.handleSubmit.bind(this)}>

          <Label>
            <PaymentDiv style={{textAlign:'left !important'}}>
              <FormattedMessage id="payment_text_15" />
            </PaymentDiv>
            <input
                   name="name" type="text" required placeholder="Name on card"/>          </Label>
          <Label>
            <PaymentDiv style={{textAlign:'left !important'}}>
              <FormattedMessage id="payment_text_16" />            </PaymentDiv>

              {this.props.countdown > 0 ? (
              <span className="async-message" style={{display: this.state.ready ? 'none':'block'  }}>
                Loading Form in {this.props.countdown}...
              </span>
            ) : (
              ''
            )}
            {this.props.stripe ? (
              <CardElement
                onReady={this.handleReady.bind(this)}
                onChange={this.handleChange}
                {...createOptions()}
              />
            ) : (
              <div className="StripeElement loading" />
            )}
          </Label>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <PayButton disabled={!this.props.stripe}> <FormattedMessage id={'payment_text_17'}/></PayButton>
        </form>
        </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class CreditCardPay extends Component<any,any> {
  constructor(props) {
    super(props)

    this.state = {
      stripe: null,
      countdown: 3,
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // Remove our existing Stripe script to keep the DOM clean.
   // document.getElementById('stripe-script').remove();
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.id = 'stripe-script';
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const countdown = setInterval(() => {
        this.setState({countdown: this.state.countdown - 1});
      }, 1000);
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        clearInterval(countdown);
        this.setState({
          stripe: window.Stripe('pk_live_FrncSr45TM7v7k9MzczmpLlI00iT1gKayE'),
        });
      }, 3000);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider postBooking={this.props.postBooking} stripe={this.state.stripe}>
        <Wrapper>
        <Elements>
          <CardForm
            postBooking={this.props.postBooking}
            amount={this.props.amount}
            countdown={this.state.countdown}
            handleResult={this.props.handleResult}
          />
        </Elements>
        </Wrapper>
      </StripeProvider>
    );
  }
}
